<template>
  <button
    :disabled="disabled"
    :class="buttonClass"
    :style="buttonStyles"
    :type="buttonType"
    :onclick="buttonOnClick">
    <spinner
      v-if="loading"
      overlay="absolute"
      :overlay-opacity="0.25"
      :inline="true"
      uk-scrollspy="cls: uk-animation-fade;"
      :size="spinnerSize"
      :type="spinnerType"
      :color="spinnerColor"></spinner>
    <span class="button-inner" :class="innerClass">
      <span v-if="loading" class="height-keeper">.</span>
      <icon v-if="icon" class="button__icon" v-bind="iconProps"></icon>
      <!-- @slot button content. Icon may appear before or after it-->
      <slot v-if="showSlot" name="text">
        <span v-if="showSlot" class="button-text">
          <slot>{{ finalText }}</slot>
        </span>
      </slot>

      <icon
        v-if="iconEnd"
        class="button__icon-end"
        v-bind="iconEndProps"></icon>
    </span>
  </button>
</template>

<script>
import FormButtonMixin from "@/client/extensions/mixins/baseComponents/form/formButton.js";

export default {
  mixins: [FormButtonMixin],
  props: {
    theme: {
      type: String,
      default: config.style.weUI.button.defaultTheme,
    },
    spinnerType: {
      type: String,
      default: "circle", // can be "cubes"
    },
    iconsAbsolute: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: function () {
    return {};
  },

  computed: {
    safeTheme() {
      if (!this.theme) {
        if (this.type === "primary") return "lead";

        if (this.type === "secondary") return "alt";

        if (this.type === "danger") return "danger";
        return this.type;
      }

      // convert "primary" and "secondary" to lead and alt, for legacy's sake
      if (this.theme === "primary") return "lead";

      if (this.theme === "secondary") return "alt";

      return this.theme;
    },
    buttonClass() {
      let className = (this.$.attrs.class || "").split(" ");

      className.push("button");

      // implement button type that modifies appearance
      className.push("button--" + this.safeTheme);

      // implement other button types
      if (["reset", "default"].includes(this.type)) {
        className.push("button--default");
      }

      // implement button size
      if (["small", "large", "medium", "s", "l", "m"].includes(this.size)) {
        className.push("button--" + this.size);
      }

      if (this.slim === true || this.slim === "true") {
        className.push("button--slim");
      }

      // implement button loading class
      if (this.loading) {
        className.push("button--" + "loading");
      }

      if (this.isFullWidthComputed) {
        className.push("width-expand");
      }

      if (this.iconsAbsolute === true || this.iconsAbsolute === "true") {
        className.push("button--" + "icons-absolute");
      }
      className.push(this.class);

      return className;
    },

    buttonStyles() {
      let styles = {};

      if (this.slim) {
        //    styles['padding-left'] = '10px';
        //    styles['padding-right'] = '10px';
      }

      return styles;
    },

    buttonType() {
      if (["submit", "reset"].includes(this.type)) {
        return this.type;
      }

      if (["submit", "reset"].includes(this.behaviour)) {
        return this.behaviour;
      }

      return "";
    },

    spinnerSize() {
      if (this.size === "large") {
        return 1.3;
      }

      if (this.size === "small") {
        return 0.45;
      }

      return 1;
    },
    spinnerColor() {
      return "currentColor";
    },
    iconProps() {
      return {
        icon: this.icon,
        size: this.iconSize || this.iconRatio,
      };
    },

    iconEndProps() {
      return {
        icon: this.iconEnd,
        size: this.iconSize || this.iconRatio,
      };
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.button {
  position: relative;
}

.button .button-inner {
  display: flex;
  align-items: center;
  justify-content: center;

  .height-keeper {
    width: 0;
    pointer-events: none;
    overflow: hidden;
    color: transparent;
  }
}

.button.button--loading .button-inner {
  .button-text,
  .button__icon,
  .button__icon-end {
    opacity: 0;
    color: transparent;
    pointer-events: none;
    visibility: hidden;
  }
}

.button.button--loading,
.button.button--loading[disabled] {
  cursor: wait;
}

.button[disabled] {
}

.button-text {
  white-space: nowrap;
}
</style>
